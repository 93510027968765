import "./styles.scss"

import React from "react"

const Tourists = () => {
  return (
    <section className="investor-tourists">
      <div className="container-fluid">
        <div className="investor-tourists__wrapper">
          <div className="row flex-column-reverse flex-lg-row">
            <div className="col-xl-5 offset-xl-1 col-lg-6">
              <img
                src={
                  require("assets/images/investor-tourists-chart.svg").default
                }
                alt=""
              />
              <div className="investor-tourists__chart-info">
                <strong>
                  Szacunkowa liczba gości odwiedzających Kraków w latach
                  2005-2019
                </strong>
                <small>
                  *źródło: BIP Miasto Kraków
                  <a
                    href="https://www.bip.krakow.pl/zalaczniki/dokumenty/n/258711/karta"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.bip.krakow.pl/zalaczniki/dokumenty/n/258711/karta
                  </a>
                </small>
              </div>
            </div>

            <div className="col-xl-5 offset-xl-1 col-lg-6">
              <h2>Rośnie liczba turystów odwiedzających miasto</h2>
              <div className="investor-tourists__inner">
                <p>
                  W 2021 roku Kraków odwiedziło <strong>11,3 mln</strong>{" "}
                  turystów z czego największą grupę - <strong>10,385</strong>{" "}
                  mln stanowili turyści krajowi, a zagraniczni{" "}
                  <strong>0,915 mln</strong>.
                </p>
                <p>
                  Pomimo pandemii i niesprzyjających dla rynku turystycznego
                  okoliczności, to właśnie w małopolsce odnotowano najwyższy
                  wzrost liczby turystów w kwietniu i maju spośród wszystkich
                  województw. Choć miasto nie wróciło jeszcze do swojej
                  świetności, prognozy są optymistyczne. Znajduje to
                  uzasadnienie w statystykach, gdyż w 2021 Kraków odwiedziło o
                  ponad <strong>3 mln</strong> turystów więcej niż w roku 2020,
                  którzy wydali <strong>5,5 mld złotych</strong>.
                </p>
              </div>
              <small>
                Źródło: Polska Agencja Prasowa, Forum Turystyki, Małopolska
                Agencja Turystyczna
              </small>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Tourists
