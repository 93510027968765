import "./styles.scss"

import React from "react"
import PageHeader from "components/PageHeader"

const MarketAdvantages = () => {
  return (
    <section className="investor-market-advantages">
      <div className="container-fluid">
        <PageHeader title="Zalety rynku" type="sub" />

        <div className="row">
          <div className="col-xl-5 col-md-5">
            <img
              src={
                require("assets/images/investor-market-advantages-image-1.jpg")
                  .default
              }
              alt=""
            />
          </div>
          <div className="col-xl-5 offset-xl-1 col-md-7">
            <div className="investor-market-advantages__wrapper">
              <div>
                <h2>Sytuacja ekonomiczna mieszkańców Krakowa poprawia się</h2>
                <div className="investor-market-advantages__inner">
                  <p>
                    Sytuacja ekonomiczna mieszkańców polepsza się w szybkim
                    tempie. Według danych GUS (listopad 2021) przeciętne
                    wynagrodzenie w sektorze przedsiębiorstw w Krakowie wyniosło
                    7 253 zł brutto. To o ponad tysiąc złotych więcej niż
                    średnia dla całej małopolski, a także aż o 12,3%. więcej niż
                    przed rokiem i aż o 63% więcej niż wynosiła średnia
                    krakowska w roku 2015.
                  </p>
                  <p>
                    Tak korzystna sytuacja ekonomiczna mieszkańców miasta
                    sprawia, że to jedna z najbardziej atrakcyjnych pod kątem
                    życia lokalizacji na mapie Polski. Polepszająca się jakość
                    życia mieszkańców wpływa na rosnący popyt na inwestycje
                    mieszkaniowe.
                  </p>
                </div>
              </div>
              <small>Źródło: GUS</small>
            </div>
          </div>
        </div>

        <div className="investor-market-advantages__divider" />

        <div className="row flex-column-reverse flex-md-row">
          <div className="col-xl-5 offset-xl-1 col-md-7">
            <div className="investor-market-advantages__wrapper">
              <div>
                <h2>Coraz więcej mieszkań oddanych do użytku w Krakowie</h2>
                <div className="investor-market-advantages__inner">
                  <p>
                    Z najnowszych danych Głównego Urzędu Statystycznego wynika,
                    że w Krakowie znajduje się niemal 417 tys. mieszkań. W
                    pierwszym półroczu 2021 roku oddano do użytkowania 4,1 tys.
                    mieszkań i wydano pozwolenia budowlane na powstanie
                    kolejnych 5,5 tys. lokali mieszkalnych. To niemal 40% więcej
                    wydanych pozwoleń na budowę niż w roku 2019 oraz 2020. W
                    pierwszym półroczu 2021 roku rozpoczęto w Krakowie budowę
                    5,3 tys. mieszkań, z czego 4,6 tys. w ramach inwestycji
                    deweloperskich.
                  </p>
                </div>
              </div>
              <small>Źródło: GUS</small>
            </div>
          </div>
          <div className="col-xl-5 offset-xl-1 col-md-5">
            <img
              src={
                require("assets/images/investor-market-advantages-image-2.jpg")
                  .default
              }
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default MarketAdvantages
