import "./styles.scss"

import React from "react"

const TopInfo = () => {
  return (
    <section className="investor-top-info">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-5 offset-xl-1 col-lg-7">
            <h2>Liczba ludności w stolicy małopolski stale rośnie</h2>
            <div className="investor-top-info__inner">
              <p>
                <strong>Kraków</strong> to drugie pod względem wielkości i
                liczby ludności miasto w Polsce. Liczba zameldowanych
                mieszkańców w czerwcu 2020 roku wyniosła 781 tys. zaś w całym
                województwie małopolskim mieszka niemal 3,5 mln osób. Według
                najnowszych danych gęstość zaludnienia osiągnęła 2389 os./km².
                Wzrost liczby mieszkańców to ogromny atut, gdyż pociąga to za
                sobą wzrost inwestycji oraz skutkuje dynamicznym rozwojem i
                postępującą modernizacją miasta.
              </p>
              <p>
                <strong>
                  Kraków zdecydowanie wyróżnia się na tle innych miast w kraju
                </strong>
                . Jest to jednocześnie miasto akademickie, biznesowe i
                turystyczne. Przybywającym na studia oferuje atrakcyjne kierunki
                rozwoju, szukającym pracy dobrze płatne stanowiska pracy, a
                turystom liczne atrakcje.
              </p>
            </div>

            <div className="investor-top-info__row">
              <div className="investor-top-info__item">
                <div className="investor-top-info__item-image">
                  <img
                    src={
                      require("assets/images/investor-top-info-ico-1.svg")
                        .default
                    }
                    alt=""
                  />
                </div>
                <p>Miasto akademickie</p>
              </div>
              <div className="investor-top-info__item">
                <div className="investor-top-info__item-image">
                  <img
                    src={
                      require("assets/images/investor-top-info-ico-2.svg")
                        .default
                    }
                    alt=""
                  />
                </div>
                <p>Miasto biznesowe</p>
              </div>
              <div className="investor-top-info__item">
                <div className="investor-top-info__item-image">
                  <img
                    src={
                      require("assets/images/investor-top-info-ico-3.svg")
                        .default
                    }
                    alt=""
                  />
                </div>
                <p>Miasto turystyczne</p>
              </div>
            </div>
          </div>

          <div className="col-xl-4 offset-xl-2 col-lg-5">
            <div className="investor-top-info__info">
              <h5>
                781 <span>tys</span>
              </h5>
              <h4>Liczba zameldowanych mieszkańców</h4>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TopInfo
