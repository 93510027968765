import "./styles.scss"

import React from "react"

const Pricing = () => {
  return (
    <section className="investor-pricing">
      <div className="container-fluid">
        <div className="row flex-column-reverse flex-lg-row">
          <div className="col-lg-6">
            <img
              src={require("assets/images/investor-pricing-chart.svg").default}
              alt=""
            />
            <div className="investor-pricing__chart-data">
              <strong>
                Średnia cena: <span>531 067 zł</span>
              </strong>
              <strong>
                Średni metraż:{" "}
                <span>
                  53,9 m<sup>2</sup>
                </span>
              </strong>
              <strong>
                Średnia cena za mkw. <span>10 128 zł</span>
              </strong>
            </div>
            <small>źródło: RynekPierwotny.pl</small>
          </div>

          <div className="col-xl-5 offset-xl-1 col-lg-6">
            <h2>W czasie pandemii ceny mieszkań w Krakowie mocno w górę</h2>
            <div className="investor-pricing__inner">
              <p>
                W 2020 na mieszkanie prosto od dewelopera na krakowskim rynku
                mieszkaniowym, trzeba było wydać 9 089 zł/mkw., co przekłada się
                na wzrost średniej stawki w ciągu roku o ponad 9%. W ciągu
                zaledwie jednego miesiąca, tzn. w marcu 2021 w porównaniu do
                lutego, średnia cena wzrosła o 257 zł/mkw. do poziomu 10 001
                zł/mkw. Tym samym Kraków dołączył do ciągle elitarnego grona
                miast, gdzie średnie ceny metra kwadratowego są już wyrażane
                pięciocyfrowymi kwotami.
              </p>
              <div className="investor-pricing__row">
                <div className="investor-pricing__item">
                  <h3>
                    9 089 <span>zł/mkw</span>
                  </h3>
                  <p>2020</p>
                </div>
                <h5>VS</h5>
                <div className="investor-pricing__item">
                  <h3>
                    10 001 <span>zł/mkw</span>
                  </h3>
                  <p>2021</p>
                </div>
              </div>
            </div>
            <small>Źródło: Otodom</small>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Pricing
