import "./styles.scss"

import React from "react"

const Families = () => {
  return (
    <section className="investor-families">
      <div className="container-fluid">
        <div className="investor-families__wrapper">
          <div className="row align-items-center flex-column-reverse flex-lg-row">
            <div className="col-xl-5 col-lg-5">
              <img
                src={
                  require("assets/images/investor-families-image.jpg").default
                }
                alt=""
              />
            </div>

            <div className="col-xl-5 offset-xl-1 col-lg-7">
              <h2>
                Wygodny obiekt
                <br /> dla rodzin z dziećmi
              </h2>
              <div className="investor-families__inner">
                Budynek stanowi także atrakcyjną lokalizację dla rodzin z
                dziećmi. W dzielnicy Krowodrza znajduje się aż 16 publicznych i
                21 niepublicznych przedszkoli, a także szkoły podstawowe i
                licea. Lokalizacja w centrum miasta i dobre połączenie
                komunikacją miejską w inne rejony Krakowa znacznie ułatwia
                dotarcie także do placówek zlokalizowanych w innych dzielnicach.{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Families
