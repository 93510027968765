import "./styles.scss"

import React from "react"
import PageHeader from "components/PageHeader"

const DistrictAdvantages = () => {
  return (
    <section className="investor-district-advantages">
      <div className="container-fluid">
        <PageHeader title="Zalety dzielnicy" type="sub" color="green" />

        <div className="row align-items-center">
          <div className="col-xl-5 offset-xl-1 col-lg-6">
            <h2>Idealna lokalizacja dla młodych, aktywnych osób</h2>
            <div className="investor-district-advantages__inner">
              <p>
                Nieruchomość zlokalizowana jest w pobliżu ważnych punktów na
                mapie Krakowa. To idealne miejsce dla młodych, aktywnych osób,
                które poszukują lokalizacji blisko centrum miasta z dobrym
                dojazdem do pracy. Mazowiecka 115 znajduje się w bliskiej
                odległości od centrum biurowego Biprostal (450m), licznych
                biurowców na ul. Armii Krajowej, wydziałów szkół wyższych oraz
                Akademii Górniczo-Hutniczej.
              </p>
            </div>
          </div>
          <div className="col-xl-5 offset-xl-1 col-lg-6">
            <img
              src={
                require("assets/images/investor-district-advantages-image-1.jpg")
                  .default
              }
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default DistrictAdvantages
