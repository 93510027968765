import "./styles.scss"

import React from "react"

const Grid = () => {
  return (
    <section className="investor-grid">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-5 offset-xl-1 col-md-6">
            <div className="investor-grid__wrapper">
              <div>
                <h2>Duże zainteresowanie mieszkaniami na Krowodrzy</h2>
                <div className="investor-grid__inner">
                  <p>
                    Ulica Mazowiecka znajduje się w ścisłym centrum dzielnicy
                    Krowodrza. To dzielnica o największym zagęszczeniu
                    deweloperskich inwestycji. Wynika to z postrzegania tej
                    dzielnicy jako najbardziej atrakcyjnej, pod względem
                    stosunku ceny do lokalizacji*
                  </p>
                </div>
              </div>
              <small>*wg. raportu Krakowski rynek nieruchomości 2018</small>
            </div>
          </div>
          <div className="col-xl-5 offset-xl-1 col-md-6">
            <img
              src={require("assets/images/investor-grid-image-1.jpg").default}
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Grid
