import "./styles.scss"

import React from "react"

const BottomGrid = () => {
  return (
    <section className="investor-bottom-grid">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-xl-5 offset-xl-1 col-lg-6">
            <h2>
              Wysoki standard
              <br /> inwestycji
            </h2>
            <div className="investor-bottom-grid__inner">
              <p>
                Mazowiecka 115 to inwestycja, w której starannie dopracowany
                jest każdy, najdrobniejszy detal. Estetyka i dokładność
                wykończenia to cechy szczególne obiektu. Budynek został
                rzetelnie zaplanowany i zrealizowany z dbałością o detale, które
                mają ogromne znaczenie dla świadomych i wymagających nabywców.
              </p>
            </div>
          </div>

          <div className="col-xl-5 offset-xl-1 col-lg-6">
            <img
              src={
                require("assets/images/investor-bottom-grid-image-1.jpg")
                  .default
              }
              alt=""
            />
          </div>
        </div>

        <div className="investor-bottom-grid__divider" />

        <div className="row align-items-center flex-column-reverse flex-lg-row">
          <div className="col-xl-5 col-lg-6">
            <img
              src={
                require("assets/images/investor-bottom-grid-image-2.jpg")
                  .default
              }
              alt=""
            />
          </div>
          <div className="col-xl-5 offset-xl-1 col-lg-6">
            <h2>
              Unikalny design
              <br /> i charakter obiektu
            </h2>
            <div className="investor-bottom-grid__inner">
              <p>
                Prostota i klasyczna elegancja to niewątpliwe atuty Mazowieckiej
                115. Duża ilość przeszkleń, ceglane elementy, proste linie i
                wykończenia tworzą spójną, nienachalną koncepcję wizualną, która
                zgrabnie wpisuje się w otoczenie. Mazowiecka 115 to nowoczesna
                koncepcja architektoniczna, która dzięki czterem odrębnym
                segmentom fasady przywodzi na myśl pierzeje dostojnych kamienic
                Starego Miasta.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BottomGrid
