import React from "react"

import Layout from "components/Layout"
import PageHeader from "components/PageHeader"
import {
  TopInfo,
  MarketAdvantages,
  Pricing,
  Grid,
  Tourists,
  DistrictAdvantages,
  Families,
  BottomGrid,
} from "page_components/investor"

const Investor = () => (
  <Layout seo={{ title: "Dla inwestora" }}>
    <PageHeader title="Dla inwestora" />
    <TopInfo />
    <MarketAdvantages />
    <Pricing />
    <Grid />
    <Tourists />
    <DistrictAdvantages />
    <Families />
    <BottomGrid />
  </Layout>
)

export default Investor
